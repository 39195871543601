<template>
  <div v-if="isValidToken" class="append-info container">
    <div class="append-info__wrapper">

      <div class="append-info__logo">
        <img class="logo" @click="$router.replace('/')" src="@/assets/img/header/logo.svg" alt="logo"/>
      </div>
      <div class="append-info__title">Зарегистрироваться</div>
      <div class="append-info__description">
        <span>E-mail</span>
        (указанный e-mail был использован для оплаты курса)
      </div>

      <form class="append-info__form" @submit.prevent="handleSubmitForm">

        <form-input class="append-info__form-input"
                    v-model="userEmail"
                    type="text"
                    readonly
                    placeholder="Введите свой e-mail"/>

        <form-input class="append-info__form-input"
                    v-model="form.password"
                    :validator="$v.form.password"
                    label="Пароль *"
                    type="password"
                    placeholder="Введите пароль"/>

        <div class="append-info__repeat-password">
          <div class="append-info__repeat-password-error">{{ correctPassword }}</div>
          <form-input class="append-info__form-input"
                      :class="{'form-input--error': correctPassword}"
                      v-model="repeatPassword"
                      type="password"
                      placeholder="Подтвердите пароль"/>
        </div>

        <form-input class="append-info__form-input"
                    v-model="form.fullName"
                    :validator="$v.form.fullName"
                    label="Фамилия Имя *"
                    type="text"
                    placeholder="Фамилия Имя"/>

        <form-input class="append-info__form-input"
                    v-model="form.phoneNumber"
                    type="text"
                    label="Номер телефона *"
                    :mask="'+7 (###) ### ## ##'"
                    :validator="$v.form.phoneNumber"
                    placeholder="+7 (904) --- -- --"/>

        <div class="append-info__date-picker">
          <label class="append-info__title-date-picker" for="date-picker">Дата рождения</label>
          <el-date-picker
              popper-class="user-date-birthday"
              type="date"
              id="date-picker"
              v-model="form.birthday"
              placeholder="Выберите дату рождения"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>

        <button class="append-info__form-btn">
          <div v-if="!loading" class="append-info__form-btn-title">Зарегистрироваться</div>
          <i v-else class="el-icon-loading"></i>
        </button>

      </form>

    </div>
    <logo-wrapper/>
  </div>

  <div v-else v-loading="true" style="width: 100vw;height: 100vh"></div>
</template>

<script>
import {authService} from "../auth.service";
import logoWrapper from "../forms/components/LogoWrapper";
import formInput from "../../components/FormInput";

import {validationMixin} from "vuelidate";
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";

export default {
  name: "AppendInfo",
  mixins: [validationMixin],
  components: {
    logoWrapper,
    formInput
  },
  data() {
    return {
      isValidToken: false,
      loading: false,
      flagError: false,
      userEmail: '',
      form: {
        password: '',
        fullName: '',
        phoneNumber:'',
        birthday: null
      },
      repeatPassword: '',

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
    }
  },
  computed: {
    token() {
      return this.$route.params.token
    },
    correctPassword() {
      if (this.form.password !== this.repeatPassword && this.form.password.length && this.flagError)
        return 'Пароль не совпадает'
      else
        return ''
    }
  },
  mounted() {
    this.isValidToken = true
    authService.isValidTokenForAppendInfo(this.token).then((data) => {
      this.isValidToken = true
      this.userEmail = data.email
    })
  },
  methods: {
    async handleSubmitForm() {
      this.flagError = true

      if(validate(this.$v) && this.form.password === this.repeatPassword){
        this.loading = true

        if(this.form.birthday) {
          const dateBirthday = new Date(this.form.birthday.toString())
          this.form.birthday = `${dateBirthday.getFullYear()}-${dateBirthday.getMonth() + 1}-${dateBirthday.getDate()}`
        }
        else
          delete this.form.birthday

        await authService.appendInfoForNewUser(this.form, this.token).finally(()=> {
          this.loading = false
          this.flagError = false
        })
        await authService.auth({email:this.userEmail,password:this.form.password}).then(()=>{
          localStorage.removeItem('userInfo')
          this.$router.push('/profile')
        })
      }

    }
  },
  validations: {
    form: {
      password: {required, minLength: minLength(6), maxLength: maxLength(64)},
      fullName: {required, minLength: minLength(1), maxLength: maxLength(40)},
      phoneNumber: {required, phoneRu: minLength(18)}
    },
  }

}
</script>

<style lang="scss">
.append-info {
  display: flex;

  &__wrapper {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }

  &__logo {
    width: 142px;

    margin: 32px auto 21px auto;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    color: #121212;

    transition: 0.2s;
    margin-bottom: 30px;
  }

  &__description {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: #4F4F4F;
    margin-left: 30px;

    > span {
      font-size: 14px;
      color: #121212;
    }
  }

  &__title-date-picker {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;


    color: #000000;
    margin-left: 30px;
  }

  &__repeat-password{
    position: relative;

    &-error{
      position: absolute;
      right: 0px;
      top: -12px;

      margin-left: auto;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #FF0000;
    }
  }

  &__form-input {
    margin: 0px 0px;
    margin-bottom: 19px;
  }

  &__form-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none !important;
    height: 57px;
    width: 100%;
    margin: 20px 0px;
    background: #1454F2;
    border-radius: 50px;
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;

    &:hover {
      background: #1454f2de;
    }
  }

  &__form-btn-title {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    margin-right: 15px;
  }

  &__form {
    .form-input__input {
      border-radius: 200px;
      height: 50px;
    }

    .form-input__label {
      .form-input__eye {
        bottom: 15px;
      }
      span {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;

        margin-left: 30px;
      }
    }


    .el-date-editor {
      width: 100%;
      margin-bottom: 24px;
      margin-top: 2px;

      .el-input__inner {
        height: 48px;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        border: 0.5px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 100px;
        transition: 0.2s;

        &:focus {
          border-color: #7B8DFF;
        }

        &::placeholder {
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;

        }
      }
    }
  }
}

.user-date-birthday {
  .el-date-picker__header {
    font-family: Montserrat, sans-serif;
  }

  .el-picker-panel__content {
    font-family: Montserrat, sans-serif;
  }
}
</style>